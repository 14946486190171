import React, { useState, useEffect } from 'react'
import {
  Heading,
  Box,
  TextInput,
  FormField,
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Image,
} from 'grommet'
import axios from 'axios'

type Props = {}
const SearchBar: React.FC<Props> = () => {
  const [apiKey, setApiKey] = useState<string>('')
  const [searchWord, setSearchWord] = useState(`Star Wars`)
  const [movies, setMovies] = useState<any>({})

  useEffect(() => {
    if (apiKey) {
      localStorage.setItem('apiKey', apiKey)
    }
  }, [apiKey])

  useEffect(() => {
    const localApiKey = localStorage.getItem('apiKey')
    if (localApiKey && localApiKey !== 'undefined') {
      setApiKey(localApiKey)
    }
  }, [])

  const handleSearch = async () => {
    if (apiKey) {
      //http://www.omdbapi.com/
      const result = await axios(
        `https://www.omdbapi.com/?apikey=${apiKey}&t=${searchWord}`
      )
      setMovies(result.data)
    }
  }

  return (
    <>
      <Box pad="large">
        <Heading margin={{ top: '0' }}>Demo: Movie Search</Heading>
        <FormField label="API Key">
          <TextInput
            placeholder="type here"
            value={apiKey}
            onChange={(e) => setApiKey(e.target.value)}
          />
        </FormField>
        <FormField label="Search Word">
          <TextInput
            placeholder="type here"
            value={searchWord}
            onChange={(e) => setSearchWord(e.target.value)}
          />
        </FormField>
        <Box direction="row" gap="medium">
          <Button
            type="submit"
            primary
            label="Search"
            onClick={() => handleSearch()}
          />
        </Box>
        <Box margin={{ top: '50px' }}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell scope="row">
                  <strong>Year</strong>
                </TableCell>
                <TableCell>{movies.Year}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell scope="row">
                  <strong>Title</strong>
                </TableCell>
                <TableCell>{movies.Title}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell scope="row">
                  <strong>Actors</strong>
                </TableCell>
                <TableCell>{movies.Actors}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Box height="small" width="small">
            <Image margin="small" fit="cover" src={movies.Poster} />
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default SearchBar
