import React from 'react'
import { Grommet } from 'grommet'
import SearchBar from './SearchBar'

const theme = {
  global: {
    colors: {
      brand: '#228BE6',
    },
    font: {
      family: 'Roboto',
      size: '18px',
      height: '20px',
    },
  },
}

function App() {
  return (
    <Grommet theme={theme}>
      <SearchBar />
    </Grommet>
  )
}

export default App
